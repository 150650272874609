<style scope>
  body{
    min-height: 100%;
  }
  .tab-list{
    border-bottom: 1px solid #eeeeee;
  }
  .tab-list ul{
    display: -webkit-box;
  }
  .tab-list ul li{
    -webkit-box-flex: 1;
    text-align: center;
    font-size: 0.875rem;
    line-height: 40px;
    height: 40px;
  }
  .tab-list ul li div{
    display: inline-block;
    width: 60px;
  }
  .tab-list ul li span{
    position: relative;
    top: -1px;
    border-bottom: 2px solid transparent;
  }
  .tab-list ul li.on div span{
    display: block;
    color: #656fef;
    border-bottom: 2px solid #656fef;
  }
  .tab-con{
    min-height: calc(100% - 41px);
    overflow: hidden;
  }
  .near-used{
    padding: 15px;
  }
  .type-con{
    overflow: hidden;
    height: 100%;
  }
  .type-con .second-list{
    float: left;
    background: rgba(255,255,255,0.5);
    height: 100%;
  }
  .type-con .second-list li{
    height: 35px;
    line-height: 35px;
    width: 80px;
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
    color: #777777;
  }
  .type-con .second-list li.on{
    background: #547CFF;
    color: #fff;
    border-bottom: 1px solid #547CFF;
  }
  .type-con .bg-list{
    margin-left: 80px;
    padding: 15px 10px;
  }
  .bg-list-con{
    overflow: hidden;
  }
  .bg-list-con li{
    float:left;
    width: 22%;
    margin-right: 4%;
    margin-bottom: 10px;
    text-align: center;
    color: #999;
  }
  .bg-list .bg-list-con li:nth-of-type(4n){
    margin-right: 0;
  }
  .near-used .bg-list-con li{
    width: 16%;
    margin-right: 5%;
  }
  .near-used .bg-list-con li:nth-of-type(5n){
    margin-right: 0;
  }
  .bg-list-con li a{
    display: block;
    border-radius: 0px;
    overflow: hidden;
    position: relative;
    /*background: #4e43e1;*/
    color: #fff;
  }
  .bg-list-con li img{
    width: 100%;
    display: block;
  }
  .bg-list-con li.on a::after{
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: url("../../assets/image/selected01.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .bg-list-con li p{
    margin-top: 3px;
  }
  .bg-list-con li.on p{
    color: #4e43e1;
  }
  .booknull{
    margin-top: 180px;
  }
  .pagebgimg{
      position: fixed;
      top: 0;
      z-index: -1;
      width: 100%;height: 100%;
    }
    .pagebgimg>img{
      width: 100%;height: 100%;
    }
</style>
<template>
  <div class="chapter-bg">
    <header-title titleCon="CG库" titleBtnStr="完成" @titleLeftBtn="titleFnReturn" @titleFnBtn="titleFnListener"></header-title>
    <div class="chpater-bg-list" id="chapterCgCon">
      <div class="tab-list">
        <ul>
          <li :class="typeActive.firstTypeIndex === 0 ? 'on': ''">
            <div><span @click="firstNearFn">最近使用</span></div>
          </li>
          <li v-for="(item, index) in ugcCgTypes" :key="index" :class="typeActive.firstTypeIndex === (index + 1) ? 'on': ''">
            <div><span @click="firstTypeFn(item.cgType, index+1)">{{item.cgTypeName}}</span></div>
          </li>
        </ul>
      </div>
      <div class="tab-con">
        <!--最近使用-->
        <div class="near-used" v-if="typeActive.firstTypeIndex === 0">
          <ul class="bg-list-con" v-if="nearList.length>0">
            <li v-for="(item, index) in nearList" :class="item.selected ? 'on': ''" :key="index">
              <a  @click="changeFace(index)">
                <span><img :src="item.cgPath + '?x-oss-process=image/resize,m_fixed,h_480,w_300'" :title="item.cgTitle"></span>
              </a>
              <p>{{item.cgTitle}}</p>
            </li>
          </ul>
          <div v-else>
            <div class="booknull">
              <div class="booknullimg">
                <img src="../../assets/image/book_null.png">
                <p>暂无使用历史~</p>
              </div>
            </div>
          </div>
        </div>
        <!--二级分类-->
        <div class="type-con" v-else>
          <div class="second-list">
            <ul>
              <li v-for="(item, index) in cgSubType" :key="index" :data-id="item.id" @click="subTypeShow(item.id, index)" :class="typeActive.subTypeIndex === index ? 'on': ''">
                {{item.subTypeName}}
              </li>
            </ul>
          </div>
          <div class="bg-list">
            <ul class="bg-list-con" v-if="ugcCgList.length>0">
              <li v-for="(item, index) in ugcCgList" :class="item.selected ? 'on': ''" :key="index">
                <a @click="changeFace(index)">
                  <span><img :src="item.cgPath + '?x-oss-process=image/resize,m_fixed,h_480,w_300'" :title="item.cgTitle"></span>
                </a>
                <p>{{item.cgTitle}}</p>
              </li>
            </ul>
            <div v-else>
              <div class="booknull">
                <div class="booknullimg">
                  <img src="../../assets/image/book_null.png">
                  <p>暂无该分类~</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
import headerTitle from '../../components/header/header-title-transparent.vue'
export default {
  components: {
    headerTitle
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png'),
      // isSelect: false,
      sltCgId: -1,
      sltCptCgActive: {
        firstTypeIndex: 0, // 一级分类选中
        firstTypeId: 11, // 一级分类选中的id
        subTypeIndex: 0, // 二级分类选中
        subTypeId: 0,
        listActive: -1 // 背景选中
      },
      typeActive: {
        firstTypeIndex: 0, // 一级分类选中
        firstTypeId: 11, // 一级分类选中的id
        subTypeIndex: 0, // 二级分类选中
        subTypeId: 0, // 二级分类选中id
        listActive: -1
      },
      ugcCgList: [], // 背景列表
      ugcCgTypes: [], // 一级分类所有列表
      cgSubType: [], // 选中的一级分类下的二级分类列表
      nearList: [] // 最近使用列表
    }
  },
  computed: {
  },
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
    // 分类接口
    this.axios({
      method: 'get',
      url: '/v1/ugcCgList',
      params: {
        cgType: '',
        cgSubType: ''
      }
    }).then((res) => {
      if (res.data.head.flag === 0) {
        // console.log(res.data.content)
        this.ugcCgTypes = res.data.content.ugcCgTypes || []
      } else {
        this.$toast(res.data.head.desc)
      }
    })
    // 最近使用
    this.axios({
      method: 'get',
      url: '/v1/recentCgs',
      params: {
        // authorId: sessionStorage.getItem('authorId')
      }
    }).then((res) => {
      if (res.data.head.flag === 0) {
        this.nearList = res.data.content.ugcCgList || []
      } else {
        this.$toast(res.data.head.desc)
      }
    })
  },
  mounted () {
    document.getElementById('chapterCgCon').style.height = (window.screen.height - 46) + 'px'
    // 默认选中
    if ((localStorage.getItem('sltCptCgActive') !== 'null') && (localStorage.getItem('sltCptCgActive') !== null) && (localStorage.getItem('sltCptCgActive') !== undefined) && (localStorage.getItem('sltCptCgActive') !== 'undefined') && (localStorage.getItem('sltCptCgActive') !== '')) {
    //   console.log(11)
      this.typeActive = JSON.parse(localStorage.getItem('sltCptCgActive'))
      this.sltCgId = parseInt(localStorage.getItem('sltCgId'))
      if (this.typeActive.firstTypeIndex !== 0) {
        this.axios({
          method: 'get',
          url: '/v1/ugcCgList',
          params: {
            cgType: this.typeActive.firstTypeId,
            cgSubType: this.typeActive.subTypeId
          }
        }).then((res) => {
          if (res.data.head.flag === 0) {
            this.cgSubType = res.data.content.ugcCgTypes[this.typeActive.firstTypeIndex - 1].cgSubType || []
            this.ugcCgList = res.data.content.ugcCgList || []
            this.ugcCgList[this.typeActive.listActive].selected = true
          } else {
            this.$toast(res.data.head.desc)
          }
        })
      } else {
        this.axios({
          method: 'get',
          url: '/v1/recentCgs',
          params: {
            // authorId: sessionStorage.getItem('authorId')
          }
        }).then((res) => {
          if (res.data.head.flag === 0) {
            this.nearList = res.data.content.ugcCgList || []
            this.nearList.forEach(function (event) {
              if (event.id === parseInt(localStorage.getItem('sltCgId'))) { // 最近使用选中
                event.selected = true
              }
            })
          } else {
            this.$toast(res.data.head.desc)
          }
        })
      }
    }
  },
  methods: {
    // 选择背景返回
    titleFnReturn () {
      this.$router.go(-1)
    },
    // 选择背景完成
    titleFnListener () {
      this.sltCptCgActive = this.typeActive
      if (this.nearList.length > 0 || this.ugcCgList.length > 0) { // 列表有数据
        if (this.sltCptCgActive.listActive !== -1) { // 选中背景
          this.$store.state.sltCptCgActive = this.sltCptCgActive
          localStorage.setItem('sltCptCgActive', JSON.stringify(this.sltCptCgActive))
          localStorage.setItem('sltCgId', this.sltCgId)
          if (this.sltCptCgActive.firstTypeIndex === 0) { // 最近使用列表
            this.$store.state.chapterInfo.chapterCg = this.nearList[this.sltCptCgActive.listActive]
          } else { // 二级分类列表
            this.$store.state.chapterInfo.chapterCg = this.ugcCgList[this.sltCptCgActive.listActive]
          }
        } else { // 取消背景
          localStorage.setItem('sltCptCgActive', '')
          localStorage.setItem('sltCgId', '')
          this.$store.state.chapterInfo.chapterCg = {
            addTime: '',
            cgPath: '',
            cgSubType: -1,
            cgTitle: '',
            cgType: -1,
            id: '',
            rackStatus: -1,
            selected: false,
            updTime: null
          }
          this.$store.state.sltCptCgActive = {
            firstTypeIndex: '',
            firstTypeId: '',
            subTypeIndex: '',
            subTypeId: '',
            listActive: ''
          }
        }
        this.$router.go(-1)
        // this.$router.push({ path: '/chapterAdd', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, type: this.$route.query.type } })
      } else {
        this.$toast('没有该分类')
      }
    },
    // 最近使用切换
    firstNearFn () {
      this.typeActive.firstTypeIndex = 0
      this.typeActive.firstTypeId = 11
      var that = this
      this.nearList.forEach(function (event) {
        if (event.id === that.sltCgId) { // 最近使用选中
          event.selected = true
        }
      })
    },
    // 一级分类切换
    firstTypeFn (firstTypeId, firstTypeIndex) {
      this.typeActive.firstTypeIndex = firstTypeIndex
      this.typeActive.firstTypeId = firstTypeId
      this.typeActive.subTypeIndex = 0
      // console.log(this.typeActive)
      this.typeActive.subTypeId = this.ugcCgTypes[firstTypeIndex - 1].cgSubType[this.typeActive.subTypeIndex].id
      var that = this
      this.axios({
        method: 'get',
        url: '/v1/ugcCgList',
        params: {
          cgType: firstTypeId,
          cgSubType: this.ugcCgTypes[firstTypeIndex - 1].cgSubType[0].id
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          that.cgSubType = res.data.content.ugcCgTypes[firstTypeIndex - 1].cgSubType
          that.ugcCgList = res.data.content.ugcCgList
          that.ugcCgList.forEach(function (event) {
            if (event.id === that.sltCgId) { // 最近使用选中
              event.selected = true
            }
          })
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    // 二级分类切换
    subTypeShow (subTypeId, subTypeIndex) {
      this.typeActive.subTypeIndex = subTypeIndex
      this.typeActive.subTypeId = subTypeId
      var that = this
      this.axios({
        method: 'get',
        url: '/v1/ugcCgList',
        params: {
          cgType: this.typeActive.firstTypeId,
          cgSubType: subTypeId
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          that.ugcCgList = res.data.content.ugcCgList
          that.ugcCgList.forEach(function (event) {
            if (event.id === that.sltCgId) { // 最近使用选中
              event.selected = true
            }
          })
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    // 背景选择
    changeFace (index) {
      if (this.typeActive.firstTypeIndex === 0) {
        if (this.nearList[index].selected) {
          this.nearList[index].selected = false
          this.typeActive.listActive = -1
        } else {
          this.nearList.forEach(function (e) {
            e.selected = false
          })
          this.typeActive.listActive = index
          this.nearList[index].selected = true
          this.sltCgId = this.nearList[index].id
        }
      } else {
        if (this.ugcCgList[index].selected) {
          this.ugcCgList[index].selected = false
          this.typeActive.listActive = -1
        } else {
          this.ugcCgList.forEach(function (e) {
            e.selected = false
          })
          this.typeActive.listActive = index
          this.ugcCgList[index].selected = true
          this.sltCgId = this.ugcCgList[index].id
        }
      }
    }
  }
}
</script>
